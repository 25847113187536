const copyToClipboard = text => {
  let success = true

  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea')

    textArea.value = text
    textArea.classList.add('visually-hidden')

    document.body.appendChild(textArea)

    textArea.focus()
    textArea.select()

    try {
      success = document.execCommand('copy')
    } catch (err) {
      success = false
    }

    document.body.removeChild(textArea)
  } else {
    navigator.clipboard.writeText(text).then(
      () => {
        success = true
      },
      err => {
        success = false
      }
    )
  }
  return success
}

module.exports = copyToClipboard
