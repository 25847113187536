import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import YouTube from 'react-youtube'
import { useIsMounted } from 'hooks'
import { noop } from 'utils'

const Youtube = ({ onReady, list, ...props }) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [ready, setReady] = useState(false)
  const isMounted = useIsMounted()

  const showing = inView && ready

  const onPlayerReady = e => {
    if (isMounted()) {
      setReady(true)
      if (list) {
        e.target.cuePlaylist({ listType: 'playlist', list })
      }
      onReady()
    }
  }
  return (
    <YoutubeContainer
      ref={ref}
      tabIndex={!showing ? '0' : null}
      className={showing ? 'youtube-container-showing' : ''}
      aria-busy={!showing}
      aria-live='polite'
      aria-label={!showing ? 'Loading video' : null}
    >
      {inView && <YouTube {...props} onReady={onPlayerReady} />}
    </YoutubeContainer>
  )
}

Youtube.propTypes = {
  onReady: PropTypes.func,
  list: PropTypes.string,
  videoId: PropTypes.string,
}

Youtube.defaultProps = {
  onReady: noop,
}

const YoutubeContainer = styled.div`
  background-color: rgba(0, 0, 0, 1);
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  transition: all 0.2s ease;
  width: 100%;

  &.youtube-container-showing {
    background-color: rgba(0, 0, 0, 0);
  }

  iframe {
    height: 100% !important;
    padding-bottom: 0 !important;
    position: absolute !important;
    width: 100% !important;
  }
`
export default Youtube
