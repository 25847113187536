import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import YouTube from 'components/youtube'
import Content from 'components/content'
import Spacer from 'components/spacer'
import TitleBar from 'components/title-bar'
import Share from 'components/share'
import BlockContent from '@sanity/block-content-to-react'
import { getYoutubeVideoId } from 'utils'

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      return <a href={mark.reference.url}>{children}</a>
    },
  },
  types: {
    youtubeVideo: ({ node }) => {
      const { url } = node
      const id = getYoutubeVideoId(url)
      return <YouTube videoId={id} />
    },
  },
}

const FieldGuidePostTemplate = props => {
  const {
    data: {
      sanityFieldGuidePost: { _rawBody, title },
    },
  } = props
  return (
    <Layout seo={{ title }}>
      <TitleBar isMainTitle={false}>Guide</TitleBar>
      <StyledContent>
        <h1>{title}</h1>
        <Spacer />
        <div className='post-content'>
          <BlockContent blocks={_rawBody} serializers={serializers} />
        </div>
        <Spacer size='xl' />
        <Share title={title} className='share-section' />
      </StyledContent>
    </Layout>
  )
}

const StyledContent = styled(Content)`
  h1 {
    font-size: var(--font-size-h2);
  }

  .share-section {
    border-top: 1px solid var(--color-n30);
    padding-top: var(--size-s);
  }

  .post-content {
    a {
      color: var(--color-g400);

      :hover,
      :focus {
        color: var(--color-g700);
      }
    }
  }
`

export const pageQuery = graphql`
  query postTemplate($id: String!) {
    sanityFieldGuidePost(id: { eq: $id }) {
      _rawBody(resolveReferences: { maxDepth: 9999 })
      title
    }
  }
`

export default FieldGuidePostTemplate
