import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope'
import { FaLink } from '@react-icons/all-files/fa/FaLink'
import { FaShareAlt } from '@react-icons/all-files/fa/FaShareAlt'
import { stringify } from 'querystring'
import UnstyledButton from 'components/unstyled-button'
import { copyToClipboard, isBrowser } from 'utils'

const Share = ({ showLabel, title, text, ...props }) => {
  const { pathname } = useLocation()
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const url = `${siteUrl}${pathname}`

  const nativeShare = async () => {
    try {
      await navigator.share({
        title,
        text,
        url,
      })
      return true
    } catch (err) {
      return false
    }
  }
  return (
    <Container {...props}>
      {showLabel ? <span>Share</span> : null}
      <a
        href={`mailto:?${stringify({ subject: title, body: url })}`}
        data-rel='external'
        title='Share by Email'
        aria-label='Share by Email'
        data-network='Email'
      >
        <FaEnvelope aria-hidden='true' focusable='false' role='presentation' />
      </a>
      <UnstyledButton
        onClick={() => copyToClipboard(url)}
        title='Copy page link'
        aria-label='Copy page link'
      >
        <FaLink aria-hidden='true' focusable='false' role='presentation' />
      </UnstyledButton>
      {isBrowser && navigator && navigator.share ? (
        <UnstyledButton onClick={nativeShare} title='Share page' aria-label='Share page'>
          <FaShareAlt aria-hidden='true' focusable='false' role='presentation' />
        </UnstyledButton>
      ) : null}
    </Container>
  )
}

Share.propTypes = {
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
}

Share.defaultProps = {
  showLabel: true,
  title: '',
  text: '',
}

const Container = styled.div`
  span {
    font-size: 1.125rem;
    margin-right: var(--size-s);
  }

  a,
  button {
    background-color: var(--color-n700);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    padding: 12px;

    :hover,
    :focus {
      background-color: var(--color-g400);
    }

    :not(:last-child) {
      margin-right: var(--size-xs);
    }
  }

  svg {
    vertical-align: middle;
  }
`

export default Share
