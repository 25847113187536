const copyToClipboard = require('./copy-to-clipboard')
const getYoutubeVideoId = require('./get-youtube-video-id')
const isBrowser = require('./is-browser')
const isDefined = require('./is-defined')
const isFunction = require('./is-function')
const isNumber = require('./is-number')
const isObject = require('./is-object')
const noop = require('./noop')
const toHandle = require('./to-handle')

module.exports = {
  copyToClipboard,
  getYoutubeVideoId,
  isBrowser,
  isDefined,
  isFunction,
  isNumber,
  isObject,
  noop,
  toHandle,
}
